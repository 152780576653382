import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import Heading from '../components/Heading/Heading';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, wordPressPost, categoryFilter, categories, site, wordpressWpSettings, siteSettings } = data
    const { showAuthor } = siteSettings.options
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle, blogSlug, siteUrl } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `Blog: ${category}`
    const thisCategory = categories.edges.filter(i => i.node.slug === slug)
    const featuredImage = thisCategory[0] && thisCategory[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
    const canonicalAttr = `${siteUrl}/blog/category/${slug}/`;

    return (
      <Layout className="page-wrap">
        {/* <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} /> */}
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} desc={pageContext.name} canonical={canonicalAttr} />
        <div className="blog-head">
          <div className="wrap">
            <Breadcrumbs location={location} />
            <Heading tag="h1" className="page-title">{decodeEntities(title)}</Heading>
          </div>
        </div>
        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
          pathSuffix={`category/${slug}/`}
          showAuthor={showAuthor}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    wordpressPost: wordpressPage(slug: { eq: $slug }) {
      title
      content
      wordpress_id
      slug
      yoast {
        metaTitle: title
        metaDescription: metadesc
        canonical: canonical
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
