import React from 'react';
import OnVisible from 'react-on-visible';
import './Heading.scss';

const Heading = ({ tag = 'h1', className = '', children }) => {
  return (
    <OnVisible
      wrappingElement={tag}
      className={`heading${className ? ` ${className}` : ''}`}
      percent={60}
    >
      {children}
    </OnVisible>
  );
};

export default Heading;
